import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

// import ThemeSwitch from "./../ThemeSwitch";
import SideMenu from "./../SideMenu";
import Header from "../Header/Header";
import Loader from "../Loader";
import ModalComponent from "../ModalComponent";
import { useAppContext } from "../../context/AppContext";
import ThemeSwitch from "../ThemeSwitch";

const Body = styled(Paper)(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { black, backgroundColor } = theme?.palette[mode];
  return {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    backgroundColor: backgroundColor,
    color: black,
    borderRadius: 0,
    boxShadow: "unset",
    minWidth: "calc(100% - 138px)",
    maxWidth: "100%",
  };
});

const PageBase = styled(Grid)(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { black, backgroundColor } = theme?.palette[mode];
  return {
    position: "relative",
    backgroundColor: backgroundColor,
    color: black,
    display: "flex",
    // width: 'calc(100vw + 138px)',
    height: "100%",
    padding: 0,
    margin: 0,
    backgroundImage: 'none',
    top: '120px',
  };
});

const Layout = ({
  children,
  showSideMenu = true,
  showHeader = true,
  showThemeSwitch = true,
  isLoading = false,
  ...rest
}) => {
  const { appErrorMessage, setAppErrorMessage} = useAppContext()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsModalOpen(appErrorMessage && appErrorMessage.length > 0)
  }, [appErrorMessage])

  const handleCloseErrorModal = () => {
    setAppErrorMessage('')
  }

  return (
    <div className="gia-layout h-screen relative">
      <Loader isLoading={isLoading}>
        {showHeader && <Header />}
        <PageBase className="pagebase" container>
          {showSideMenu && (
            <Grid item className="w-fit">
              <SideMenu />
            </Grid>
          )}
          <Body item className="page-base-body" style={showSideMenu ? {paddingLeft: '138px'} : {paddingLeft: 0}}>{children && children}</Body>
        </PageBase>
      </Loader>
      {
        isModalOpen && <ModalComponent open={isModalOpen} title={"Error"} description={appErrorMessage} handleClose={handleCloseErrorModal} />
      }
      {!showSideMenu && <ThemeSwitch className="justify-start fixed bottom-0"/>}
    </div>
  );
};
export default Layout;

export default ({ fill = "#D9D9D9" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="40"
      viewBox="0 0 41 40"
    >
      <mask x="0" y="0" width="41" height="40">
        <rect x="0.5" width="40" height="40" />
      </mask>
      <g>
        <path
          fill={fill}
          d="M9.9446 32.2221H15.7779V21.9446H25.2221V32.2221H31.0554V16.3888L20.5 8.47208L9.9446 16.3888V32.2221ZM7.16669 35V15L20.5 5L33.8334 15V35H22.4446V24.7221H18.5554V35H7.16669Z"
        />
      </g>
    </svg>
  );
};

import React, { createContext, useContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  getChatHistoryService,
  updateFeedbackService,
} from "../services/historyService";
import { parseHistoryData } from "../utils";
import { useAppContext } from "../context/AppContext";
import { Enum } from "./../constants";

const HistoryContext = createContext();

const HistoryContextProvider = ({ children }) => {
  const { FALLBACK_QUERY_MIN_CHAR } = Enum;
  const { authState } = useOktaAuth();
  const {
    globalAppConfig,
    selectedBrand,
    setSelectedBrand,
    appConfig,
    setAppErrorMessage,
  } = useAppContext();

  const [historyQuery, setHistoryQuery] = useState("");
  const [chatHistory, setChatHistory] = useState({});
  const [rawChatHistory, setRawChatHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [showMoreButton, setShowMoreButtonSetter] = useState(false);
  const [showHistoryQueryError, setShowHistoryQueryError] = useState(false);

  const setShowMoreButton = (value) => {
    setShowMoreButtonSetter(value);
  };

  const clearPreviousChatHistory = () => {
    setHistoryQuery("");
    setChatHistory({});
    setShowMoreButton(false);
    setCurrentPage(1);
    setShowHistoryQueryError(false);
  };

  const getHistoryData = async ({ brand, pageNumber = 0, historyQuery }) => {
    console.log("get hisotry data", { brand });
    try {
      if (
        Array.isArray(appConfig) &&
        appConfig.length > 0 &&
        globalAppConfig?.giaHistoryUrl &&
        globalAppConfig?.giaHistoryUrl?.length > 0
      ) {
        const foundBrandIndex = appConfig.findIndex((el) => {
          const brandName = el.id.toLowerCase();
          const brandHeading = (brand || "").toLowerCase();
          return brandName.indexOf(brandHeading) >= 0;
        });
        if (foundBrandIndex >= 0) {
          setIsLoadingHistory(true);
          let brandId = appConfig[foundBrandIndex].id;
          const { email, sub: userId } = authState?.idToken?.claims || {};
          const oktaToken = JSON.parse(
            sessionStorage.getItem("okta-token-storage")
          )?.idToken?.idToken;
          const data =
            (await getChatHistoryService({
              brand: brandId,
              pageNumber,
              historyQuery,
              config: {
                email,
                userId,
                oktaToken,
                url: globalAppConfig?.giaHistoryUrl,
                apiKey: globalAppConfig?.apiKey,
              },
            })) || [];
          // console.log("data length", { pageNumber, data });
          const parsedData = parseHistoryData({
            data: data || [],
            chatHistory: pageNumber === 0 ? {} : chatHistory,
          });
          await setChatHistory(parsedData.monthlyData);
          await setRawChatHistory(parsedData.rawData);
          if (data.length < 10) {
            setShowMoreButton(false);
          } else {
            setCurrentPage(pageNumber + 1);
            setShowMoreButton(true);
            // console.log('data length show more button', { showMoreButton })
          }

          setIsLoadingHistory(false);
        }
      }
    } catch (error) {
      setIsLoadingHistory(false);
      console.log("get HistoryData Error: ", { error, showMoreButton });
      setAppErrorMessage("Some thing went wrong. Please try again.");
      return;
    }
  };

  const updateFeedback = async ({ brand, copy_id, feed_back_action }) => {
    try {
      if (
        Array.isArray(appConfig) &&
        appConfig.length > 0 &&
        globalAppConfig?.giaHistoryUrl &&
        globalAppConfig?.giaHistoryUrl?.length > 0
      ) {
        const foundBrandIndex = appConfig.findIndex((el) => {
          const brandName = el.id.toLowerCase();
          const brandHeading = (brand || "").toLowerCase();
          return brandName.indexOf(brandHeading) >= 0;
        });

        if (foundBrandIndex >= 0) {
          setIsLoadingHistory(true);
          let brandId = appConfig[foundBrandIndex].id;
          const { email, sub: userId } = authState?.idToken?.claims || {};
          const oktaToken = JSON.parse(
            sessionStorage.getItem("okta-token-storage")
          )?.idToken?.idToken;
          await updateFeedbackService({
            brand: brandId,
            copy_id,
            feed_back_action,
            config: {
              email,
              userId,
              oktaToken,
              url: globalAppConfig?.giaHistoryUrl,
              apiKey: globalAppConfig?.apiKey,
            },
          });
          setIsLoadingHistory(false);
        }
      }
    } catch (error) {
      setIsLoadingHistory(false);
      console.log("Update Feedback Error: ", { error });
      setAppErrorMessage("Some thing went wrong. Please try again.");
    }
  };
  const onShowMore = () => {
    if (selectedBrand && selectedBrand.id && selectedBrand.id.length > 0) {
      getHistoryData({
        brand: selectedBrand && selectedBrand.id && selectedBrand.id,
        pageNumber: currentPage,
      });
    }
  };
  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!globalAppConfig?.giaHistoryUrl || selectedBrand.length === 0) return;
    const timeout = setTimeout(() => {
      setShowMoreButton(false);
      setCurrentPage(0);
      if (
        selectedBrand &&
        selectedBrand.id &&
        selectedBrand.id?.length > 0 &&
        (historyQuery.length === 0 ||
          historyQuery.length >=
            parseInt(globalAppConfig?.queryMinChar || FALLBACK_QUERY_MIN_CHAR))
      ) {
        setShowHistoryQueryError(false);
        setChatHistory({});
        const payload = {
          brand: selectedBrand && selectedBrand.id,
          pageNumber: 0,
        };
        getHistoryData(
          historyQuery.length > 0 ? { ...payload, historyQuery } : payload
        );
      } else {
        setShowHistoryQueryError(true);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [historyQuery]);

  return (
    <HistoryContext.Provider
      value={{
        clearPreviousChatHistory,
        getHistoryData,
        historyQuery,
        setHistoryQuery,
        isLoadingHistory,
        setIsLoadingHistory,
        chatHistory,
        setChatHistory,
        rawChatHistory,
        setRawChatHistory,
        showMoreButton,
        setShowMoreButton,
        onShowMore,
        updateFeedback,
        selectedBrand,
        setSelectedBrand,
        setCurrentPage,
        showHistoryQueryError,
        setShowHistoryQueryError,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

const useHistoryContext = () => {
  return useContext(HistoryContext);
};

export { useHistoryContext, HistoryContext, HistoryContextProvider };

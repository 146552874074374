import React, { Fragment, useEffect } from "react";
import { Avatar } from "@mui/material";
import Layout from "../../components/Layout";
import GenericInputComponent from "../../components/GenericInputComponent";
import GradientButton from "./GradientButton";
import InputComponent from "../../components/InputComponent";
import FormComponent from "./FormComponent";
import DividerComponent from "./DividerComponent";
import ChatsComponent from "./ChatsComponent";
import BrandSelectorSwitch from "../../components/BrandSelectorLink";

import AvatarGia from "../../assets/avatar_gia.png";
import { useAppContext } from "../../context/AppContext";
import { useFormContext } from "../../context/FormContext";
import { useHistoryContext } from "../../context/HistoryContext";
import { inputs } from "./constants";

const Form = () => {
  const {
    themeMode,
    theme,
    appConfig,
    selectedBrand,
    globalAppConfig,
    isAppConfigLoading,
    isPubSubLoading,
  } = useAppContext();
  const {
    quickStartQuery,
    setQuickStartQuery,
    brand,
    inputItems,
    formState,
    setFormState,
    isFormValid,
    submitQuickQuery,
    setInputItems,
    handleSubmitForm,
    resetFormData,
    giaFormInputQuery,
    setGiaFormInputQuery,
  } = useFormContext();
  const { getHistoryData, rawChatHistory, clearPreviousChatHistory } =
    useHistoryContext();

  useEffect(() => {
    resetFormData();
  }, []); //eslint-disable-line

  useEffect(() => {
    clearPreviousChatHistory();
    if (
      Array.isArray(appConfig) &&
      appConfig.length > 0 &&
      globalAppConfig?.giaHistoryUrl &&
      globalAppConfig?.giaHistoryUrl?.length > 0
    ) {
      getHistoryData({
        brand: selectedBrand && selectedBrand.id && selectedBrand.id,
        pageNumber: 0,
      });
    }
  }, [appConfig, globalAppConfig, selectedBrand]);

  const { mid_gray } = theme?.palette[themeMode];

  const onChangeHandler = (key, value) => {
    if (key === "channels") {
      const brandObj = appConfig.find((b) => b.id === selectedBrand.id);

      const foundIndex = inputItems.findIndex((z) => z.label === "components");
      if (foundIndex >= 0) {
        const tempArray = [...inputItems];
        tempArray[foundIndex].options = brandObj?.components[value];
        setInputItems(tempArray);
      }

      setFormState((prev) => ({
        ...prev,
        [key]: value,
        components: "",
        layout: "",
      }));
    } else if (key === "components") {
      const brandObj = appConfig.find((b) => b.id === selectedBrand.id);

      const foundIndex = inputItems.findIndex((z) => z.label === "Format");
      if (foundIndex >= 0) {
        const tempArray = [...inputItems];
        tempArray[foundIndex].options =
          brandObj?.layout[formState.channels][value];
        setInputItems(tempArray);
      }

      setFormState((prev) => ({
        ...prev,
        [key]: value,
        layout: "",
      }));
    } else {
      setFormState((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleQuickQueryChange = (e) => {
    setQuickStartQuery(e.target.value);
  };

  useEffect(() => {
    if (Array.isArray(appConfig) && appConfig.length > 0) {
      const brandObj = appConfig.find((b) => b.id === selectedBrand.id);
      if (brandObj) {
        const updatedInputs = inputs.map((input) => {
          if (input.label === "Performance Objective") {
            return {
              ...input,
            };
          } else if (input.label === "Campaign") {
            return {
              ...input,
            };
          } else if (input.label === "Target Audience") {
            return {
              ...input,
            };
          } else {
            const optionsFromConfig = Array.isArray(
              brandObj[input.label.toLowerCase()]
            )
              ? brandObj[input.label.toLowerCase()] || []
              : [];
            return { ...input, options: optionsFromConfig };
          }
        });
        setInputItems([...updatedInputs]);

        const initialFormState = updatedInputs.reduce((acc, curr) => {
          acc[curr.label] = "";
          return acc;
        }, {});
        setFormState(initialFormState);
      }
    }
  }, [appConfig, brand, setInputItems, setFormState, selectedBrand]); //eslint-disable-line

  // console.log('chatHistory', { chatHistory, rawChatHistory })
  return (
    <Layout isLoading={isAppConfigLoading || isPubSubLoading}>
      <div className="w-full flex flex-col justify-center items-center mt-8 bg-transparent mb-16">
        <Avatar
          className="flex justify-center items-center mb-6"
          alt="GIA"
          src={AvatarGia}
          sx={{ width: 150, height: 150 }}
        />
        <p className="text-[40px] flex justify-center">
          What are you working on today?
        </p>

        <div className="gia-form-container flex flex-col mt-9">
          <div className="w-full flex">
            <InputComponent
              placeholder={`Type here for 'quick start' copy options`}
              inputProps={{
                "aria-label": "Type here for quick start copy options",
              }}
              value={quickStartQuery}
              onChange={handleQuickQueryChange}
            />
            <GradientButton
              style={{ marginLeft: "20px" }}
              onClick={submitQuickQuery}
              label="GIA GENERATE"
            >
              GIA GENERATE
            </GradientButton>
          </div>

          <div className="flex mt-[39px]">
            <FormComponent className="gia-form">
              <div className="mb-4">
                <InputComponent
                  placeholder="Ask GIA to generate new copy"
                  inputProps={{
                    "aria-label": "",
                  }}
                  value={giaFormInputQuery}
                  onChange={(e) => setGiaFormInputQuery(e.target.value)}
                  style={{ width: "100%", height: "52px" }}
                />
              </div>
              <DividerComponent />
              {!isFormValid && (
                <div
                  style={{ color: mid_gray }}
                  className="text-[12px] font-normal mb-[48px]"
                >
                  All fields are required
                </div>
              )}

              {inputItems &&
                inputItems.length > 0 &&
                inputItems.map((el, i) => {
                  if (el.label && Array.isArray(el.options)) {
                    return (
                      <Fragment key={i}>
                        <GenericInputComponent
                          {...el}
                          key={i}
                          value={formState[el.label || ""]}
                          onChange={(l, value) => onChangeHandler(l, value)}
                        />
                      </Fragment>
                    );
                  }
                  return null;
                })}

              <GradientButton
                disabled={!isFormValid || !giaFormInputQuery}
                style={{ width: "100%" }}
                onClick={handleSubmitForm}
                label="GIA GENERATE"
              />
            </FormComponent>
            {rawChatHistory.length > 0 && (
              <ChatsComponent
                className="gia-recent-chats"
                chats={rawChatHistory}
              />
            )}
          </div>
        </div>
      </div>

      {selectedBrand && (
        <BrandSelectorSwitch
          src={selectedBrand.src}
          label={selectedBrand.label}
          title="Change client"
        />
      )}
    </Layout>
  );
};

export default Form;

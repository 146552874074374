import { Fragment, useState, useRef } from "react";
import jsPDF from "jspdf";
import { styled, Tooltip } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ArrowDownIcon from "../../../icons/ArrowDownIcon";
import MoreButton from "../../../components/MoreButton";
import DropdownIcon from "../../../icons/DropdownIcon";
import {
  AccordionSummaryComponent,
  AccordionContainer,
  AccordionDetailsComponent,
  AccordionComponent,
} from "../../../components/AccordionComponents";
import { useHistoryContext } from "../../../context/HistoryContext";
import CopyIcon from "../../../icons/CopyIcon";
import ShareIcon from "../../../icons/ShareIcon";
import { useAppContext } from "../../../context/AppContext";

const TimePeriod = styled("div")(({ theme }) => {
  const { mode } = theme.palette;
  const { mid_gray } = theme?.palette[mode];
  return {
    color: mid_gray,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "normal",
    marginTop: "20px",
  };
});

export default (props) => {
  const { selectedBrand } = props;
  const { chatHistory = {}, showMoreButton, onShowMore } = useHistoryContext();

  const { themeMode, theme } = useAppContext();
  const { mid_gray } = theme?.palette[themeMode];

  const [copyTooltip, setCopyTooltip] = useState("Copy");

  const contentRefs = useRef({});

  const handleCopy = (data) => {
    const textToCopy = data.copy;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyTooltip("Copied!");
        setTimeout(() => setCopyTooltip("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleShare = (refId, query) => {
    try {
      const pdf = new jsPDF("p", "pt", "a4");
      const timestamp = new Date().getTime();
      const contentElement = contentRefs.current[refId];
      if (!contentElement) {
        console.error("Content element not found");
        return;
      }
      const htmlContent = `
        <div><b>${query}</b></div>
        ${contentElement.innerHTML}
      `;
      pdf.html(htmlContent, {
        callback: function (pdf) {
          const pageCount = pdf.internal.getNumberOfPages();
          for (let i = 0; i < pageCount; i++) {
            pdf.setPage(i + 1);
            pdf.internal.pageSize.height = pdf.internal.pageSize.getHeight();
          }
          pdf.save(`${selectedBrand.id}_${timestamp}.pdf`);
        },
        x: 20,
        y: 20,
        width: 555,
        windowWidth: 800,
      });
    } catch (error) {
      console.error("Failed to generate PDF", error);
    }
  };

  return (
    <div className="chats relative">
      {Object.keys(chatHistory).map((monthKey, monthIndex) => {
        const chats = chatHistory[monthKey] || [];
        return (
          <Fragment key={monthIndex}>
            <TimePeriod>{monthKey}</TimePeriod>
            {chats.map((data, i) => {
              const refId = `pdf-content-${monthKey}-${i}`;
              return (
                <Fragment key={i}>
                  <AccordionContainer>
                    <AccordionComponent id={`accordion-${monthKey}-${i}`}>
                      <AccordionSummaryComponent
                        expandIcon={<ArrowDownIcon />}
                        aria-controls="panel1-content"
                        id={`panel-${monthKey}-${i}-header`}
                      >
                        <div className="flex flex-col">
                          {data?.query}
                          <div className="neutral-500 leading-[0px]">
                            {Object.keys(data).map((key, ki) => {
                              const keyName = key.toLowerCase();
                              if (data[key].length === 0) return null;
                              if (
                                keyName.includes("product") ||
                                keyName.includes("channel") ||
                                keyName.includes("component") ||
                                keyName.includes("layout")
                              ) {
                                return (
                                  <span
                                    style={{ color: mid_gray }}
                                    className="pr-1.5 text-xs"
                                    key={ki}
                                  >
                                    {data[key]?.replaceAll("_", " ")}{" "}
                                    {ki < Object.keys(data).length ? "." : "-"}{" "}
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      </AccordionSummaryComponent>
                      <AccordionDetailsComponent>
                        <div ref={(el) => (contentRefs.current[refId] = el)}>
                          <ReactMarkdown
                            className="pb-2"
                            remarkPlugins={[
                              [remarkGfm, { singleTilde: false }],
                            ]}
                          >
                            {data?.copy}
                          </ReactMarkdown>
                        </div>
                        <div className="flex gap-4 mt-6 justify-end items-center">
                          <Tooltip title="download" arrow>
                            <button
                              onClick={() => handleShare(refId, data?.query)}
                              className="cursor-pointer"
                            >
                              <ShareIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title={copyTooltip} arrow>
                            <button
                              onClick={() => handleCopy(data)}
                              className="cursor-pointer"
                            >
                              <CopyIcon />
                            </button>
                          </Tooltip>
                        </div>
                      </AccordionDetailsComponent>
                    </AccordionComponent>
                  </AccordionContainer>
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
      {showMoreButton && (
        <MoreButton onClick={onShowMore}>
          More <DropdownIcon />
        </MoreButton>
      )}
    </div>
  );
};

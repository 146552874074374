import axios from "axios";

const getAppConfig = async (oktaToken) => {
  try {
    const response = await axios.get(process.env.REACT_APP_CONFIG_API, {
      headers: {
        Authorization: `Bearer ${oktaToken}`,
        "ocp-apim-subscription-key": process.env.REACT_APP_API_KEY,
      }
    });
    const jsonData = JSON.stringify(response.data);
    return jsonData;
  } catch (error) {
    console.error(`Error calling getAppConfig API: ${error}`);
    throw error;
  }
};
export default getAppConfig;

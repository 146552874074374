import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import styled from '@emotion/styled/macro';

const Label = styled(FormLabel)(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { gray } = theme?.palette[mode]
    return {
        color: gray,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '6px',
        '&.Mui-focused': { color: gray },
    }
})

const RadioGroupComponent = styled(RadioGroup)(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { backgroundColor } = theme?.palette[mode]
    return {
        backgroundColor,
        padding: '14px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '12px',
    }
})

const RadioButton = styled(Radio)(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { color } = theme?.palette[mode]
    return {
        color,
        padding: 0,
        margin: '0 auto',
        '&.Mui-checked': {
            color,
        }
    }
})

const RadioButtonLabel = styled('span')(({ theme, ...rest }) => { // eslint-disable-line
    const { mode } = theme.palette
    const { gray } = theme?.palette[mode]
    return {
        color:gray,
    }
})

const Captions = (({ title, subTitle, textAlign='left', ...rest }) => {
    return (
        <div className='radio-captions flex flex-col'>
            <span className={`caption-1 font-semibold flex ${textAlign === 'left'?  'justify-end': 'justify-start'}`}>{title}</span>
            <span className={`caption-2 font-normal flex justify-end`}>{subTitle}</span>
        </div>
    )
})

export default function (props) {
    const { label, labelPlacement = 'top', onChange, value = '', defaultValue = '', options = [], style = {}, captions = {right:{}, left:{}} /*, ...rest*/ } = props
    const onChangeHandler = (event, value) => {
        if (onChange) {
            onChange(event, value)
        }
    }
    return (
        <FormControl sx={{ ...style }}>
            {label && <Label id='demo-form-control-label-placement'>{label}</Label>}
            <RadioGroupComponent
                row
                aria-labelledby='demo-form-control-label-placement'
                name='position'
                defaultValue={defaultValue}
                value={value}
                onChange={onChangeHandler}
            >
                <Captions {...captions.right} {...props} textAlign='left' />
                <div className='flex flex-wrap'>
                {
                    options.map((el, i) => {
                        return (
                            <FormControlLabel
                                key={i}
                                value={el.value || ''}
                                control={<RadioButton />}
                                label={<RadioButtonLabel>{el.label || ''}</RadioButtonLabel>}
                                labelPlacement={labelPlacement}
                            />
                        )
                    })
                }
                </div>
                <Captions {...captions.left} {...props} textAlign='RIGHT'/>
            </RadioGroupComponent>
        </FormControl>
    );
}

/*
 <RadioGroup
    onChange={(e,v) => setPerformamce(v)}
    value={performance}
    style={{marginTop: '12px'}}
    label='Performance Objective'
    captions={{left: {title:'Demand', subTitle: 'Direct, rational'}, right: {title: 'Brand', subTitle:'Creative, emotion'}}}
    options={[{label: '1', value: '1'}, {label: '2', value: '2'},{label: '3', value: '3'},{label: '4', value: '4'}, ]}
    />
*/
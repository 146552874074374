import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { SearchInput } from "../../components/InputComponent";
import { useHistoryContext } from "../../context/HistoryContext";
import { useAppContext } from "../../context/AppContext";
import Chats from "./Chats";
import BrandSelectorSwitch from "../../components/BrandSelectorLink";
import NotFound from "../../components/NotFound/NotFound";
import { Enum } from "../../constants";

export default (props) => {
  const {
    currentPage,
    getHistoryData,
    historyQuery,
    setHistoryQuery,
    isLoadingHistory,
    clearPreviousChatHistory,
    rawChatHistory,
    showHistoryQueryError,
  } = useHistoryContext();
  const { FALLBACK_QUERY_MIN_CHAR } = Enum
  const { appConfig, globalAppConfig, selectedBrand, themeMode, theme } =
    useAppContext();
  const { gray } = theme?.palette[themeMode];

  useEffect(() => {
    clearPreviousChatHistory();
  }, []);

  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // console.log('brand', appConfig, selectedBrand, globalAppConfig?.giaHistoryUrl)
    if (
      globalAppConfig?.giaHistoryUrl &&
      selectedBrand &&
      selectedBrand.id &&
      selectedBrand.id.length > 0
    ) {
      // console.log('brand found', selectedBrand)
      clearPreviousChatHistory();
      // Calling the api if user is navigated to the history page from side menu
      getHistoryData({ brand: selectedBrand.id, pageNumber: currentPage });
    } else if (
      globalAppConfig?.giaHistoryUrl &&
      (!selectedBrand ||
        !selectedBrand?.id ||
        selectedBrand?.id?.length === 0) &&
      Array.isArray(appConfig) &&
      appConfig.length > 0
    ) {
      // console.log('NO brand found', selectedBrand)
      clearPreviousChatHistory();
      // if the brand is not set in local storage then pick the first element id from appConfig array
      getHistoryData({ brand: appConfig[0].id, pageNumber: currentPage });
    }
    return () => clearPreviousChatHistory();
  }, [globalAppConfig, appConfig, selectedBrand]);

  const handleHistoryQueryChange = (event) => {
    setHistoryQuery(event.target.value);
  };

  return (
    <Layout isLoading={isLoadingHistory}>
      <div className="w-[926px] flex flex-col items-center mt-16 bg-transparent mb-16 bg-inherit">
        <div
          className="w-full flex justify-start text-[16px] leading-normal font-semibold mb-[22px]"
          style={{ color: gray }}
        >
          Copy History
        </div>
        <SearchInput
          placeholder={`Search History`}
          inputProps={{
            "aria-label": "Type here for quick start copy options",
          }}
          value={historyQuery}
          onChange={handleHistoryQueryChange}
          style={{ width: "100%", marginBottom: "3px" }}
          errorLabel={showHistoryQueryError ? `query must have atleast ${parseInt(globalAppConfig?.queryMinChar || FALLBACK_QUERY_MIN_CHAR)} characters` : ''}
        />
        {rawChatHistory.length === 0 ? (
          <NotFound />
        ) : (
          <Chats selectedBrand={selectedBrand} />
        )}
      </div>
      {selectedBrand && (
        <BrandSelectorSwitch
          src={selectedBrand.src}
          label={selectedBrand.label}
          title="Change client"
        />
      )}
    </Layout>
  );
};

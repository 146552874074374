import PinIcon from "../../icons/PinIcon";
import UnpinIcon from "../../icons/UnpinIcon";
import BrandImageBackground from "./BrandImageBackground";
import { Enum } from "../../constants";

const { PINNED, UNPINNED } = Enum

export default ({ onClick, src, heading, type = UNPINNED }) => {
    return (
        <div
            className="relative cursor-pointer"
            onClick={(e) => onClick(e)}
        >
            <BrandImageBackground className="h-[246px] w-[300px] p-8 rounded-3xl flex justify-center items-center">
                {src && <img src={src} alt="" />}
            </BrandImageBackground>
            <div
                className="absolute left-[200px] bottom-[230px] cursor-pointer"
                onClick={(e) => onClick(e)}
            >
                {type === PINNED ? <UnpinIcon /> : <PinIcon />}
            </div>
            <p className="flex justify-center items-center">
                {heading}
            </p>
        </div>
    )
}
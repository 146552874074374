import { styled } from "@mui/material";
import Select from "../Select";
import { useAppContext } from "../../context/AppContext";
import { useFormContext } from "../../context/FormContext";
import { useState, useEffect } from "react";

const BrandSelectorLinkContainer = styled("div")(({ theme }) => ({
  width: window.innerWidth > 1280 ? "150px" : "100px",
}));

const BrandSelector = ({
  value = "",
  heading = "",
  label = "Select Brand",
}) => {
  const { selectedBrand, setSelectedBrand, appConfig } = useAppContext();
  const { setBrand, initializeInputs } = useFormContext();
  const [brandValue, setBrandValue] = useState("");

  useEffect(() => {
    if (selectedBrand) {
      setBrandValue(selectedBrand?.id);
    }
  }, [selectedBrand]);

  const onChange = (label, val) => {
    const brand = appConfig.find((el) => el.id === val);
    if (brand) {
      initializeInputs();
      setSelectedBrand(brand);
      setBrandValue(val);
      setBrand(val);
    }
  };

  const generateOptions = () => {
    return appConfig?.map((el) => ({
      ...el,
      label: el.name,
      value: el.id,
      name: el.name,
    }));
  };

  return (
    <BrandSelectorLinkContainer className="h-fit absolute top-[30px] right-[30px]">
      <Select
        options={generateOptions()}
        onChange={(val) => onChange(label, val)}
        value={brandValue}
        label={heading}
        style={{ marginBottom: "12px" }}
        placeHolder=""
      />
    </BrandSelectorLinkContainer>
  );
};

export default BrandSelector;

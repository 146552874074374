import React from "react";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center mt-8">
      <div className="flex justify-center items-center flex-col ">
        <h1 className="text-[24px]">No data found</h1>
      </div>
    </div>
  );
};

export default NotFound;

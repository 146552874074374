import { styled } from "@mui/material";
import DividerComponent from "../DividerComponent";
import DropdownIcon from "../../../icons/DropdownIcon";
import MoreButton from "../../../components/MoreButton";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useHistoryContext } from "../../../context/HistoryContext";

const ChatsContainer = styled("div")(
  ({ theme, showMoreButton, chatList, ...rest }) => {
    const { mode } = theme.palette;
    const { backgroundColor, light_gray } = theme?.palette[mode];
    const style = !showMoreButton
      ? {
          maxHeight: "90vh",
          height: "fit-content",
          overflowY: "auto",
        }
      : {};
    return {
      backgroundColor,
      border: `2px solid ${light_gray}`,
      borderRadius: "18px",
      width: "216px",
      padding: "20px",
      marginLeft: "20px",
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      position: "relative",
      paddingTop: 0,
      ...style,
    };
  }
);

const ChatTitle = styled("span")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { gray, backgroundColor } = theme?.palette[mode];
  return {
    color: gray,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    position: "sticky",
    top: 0,
    left: 0,
    backgroundColor: backgroundColor,
    height: "50px",
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    zIndex: 1,
  };
});

const ChatQuery = styled("span")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { gray } = theme?.palette[mode];
  return {
    color: gray,
  };
});

const ChatDate = styled("span")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { mid_gray } = theme?.palette[mode];
  return {
    color: mid_gray,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "12px",
  };
});

const ChatMessage = styled("div")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { gray } = theme?.palette[mode];
  return {
    color: gray,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "12px",
  };
});

const EllipsButton = styled("button")(({ theme }) => {
  const { mode } = theme.palette;
  const { backgroundColor, color } = theme?.palette[mode];
  return {
    color: color,
    backgroundColor: backgroundColor,
    padding: "0 10px 10px 20px",
    opacity: "0.7",
    fontSize: "30px",
    justifyContent: "flex-end",
    alignItems: "center",
  };
});

const LoadingIndicator = styled("div")(({ theme }) => ({
  color: theme.palette.gray,
  fontSize: "16px",
  marginTop: "12px", // Adjusted margin to separate from ChatTitle
}));

const Chats = ({ children, chats = [] }) => {
  const history = useHistory();
  const [chatList, setChatList] = useState([]);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const { isLoadingHistory } = useHistoryContext();

  useEffect(() => {
    if (chats.length >= 3) {
      setChatList(chats.map((el) => ({ ...el, fullMode: false })).slice(0, 3));
      setShowMoreButton(true);
    } else {
      setChatList(chats.map((el) => ({ ...el, fullMode: false })));
      setShowMoreButton(false);
    }
  }, [chats]);

  const onShowMore = () => {
    setChatList(chats);
    setShowMoreButton(false);
    history.push("/history");
  };

  const showFullMode = (index) => {
    setChatList(chatList.map((el, i) => ({ ...el, fullMode: index === i })));
  };

  return (
    <ChatsContainer chatList={chatList} showMoreButton={showMoreButton}>
      <ChatTitle>Recent Chats</ChatTitle>

      {isLoadingHistory ? (
        <LoadingIndicator>Loading...</LoadingIndicator>
      ) : (
        <>
          {chatList.map((chat, i) => (
            <div
              key={i}
              className="flex flex-col relative"
              style={
                !showMoreButton && i === chatList.length - 1
                  ? { marginBottom: "20px" }
                  : {}
              }
            >
              <DividerComponent />
              <ChatDate>{chat.date}</ChatDate>

              <div className="font-semibold text-sm">
                <ChatQuery>{chat.query}</ChatQuery>
              </div>
              <ChatMessage
                className="chat-message"
                style={
                  chat.fullMode ? {} : { height: "100px", overflowY: "hidden" }
                }
              >
                <ReactMarkdown>{chat.message}</ReactMarkdown>
              </ChatMessage>
              {!chat.fullMode && (
                <EllipsButton
                  className="absolute bottom-[0] right-[0] flex justify-center items-center h-4"
                  onClick={() => {
                    showFullMode(i);
                  }}
                  style={{ color: "white4" }}
                >
                  ...
                </EllipsButton>
              )}
            </div>
          ))}

          {showMoreButton && (
            <MoreButton onClick={onShowMore}>
              More <DropdownIcon />
            </MoreButton>
          )}

          {children && children}
        </>
      )}
    </ChatsContainer>
  );
};

export default Chats;

import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const ModalBody = styled(Box)(({theme}) => {
    const { mode } = theme.palette
    const { gray, backgroundGray } = theme?.palette[mode]
    return {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: backgroundGray,
        border: '2px solid #000',
        boxShadow: 24,
        padding: '20px',
        color: gray,
    }
});


export default ({ open, handleClose, title = "title", description = "description", closeButtonLabel = "Close" }) => {
    const [progress, setProgress] = useState(20000);
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress(prev => (prev - 2000))
        }, 2000);
    
        return () => {
          clearInterval(timer);
        };
      }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <ModalBody>
            <LinearProgress variant="determinate" value={progress} />
                <h2>{title}</h2>
                <p>
                    {description}
                </p>
                <div className='w-full flex justify-end items-center'>
                    
                <Button onClick={handleClose}>{closeButtonLabel}</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}
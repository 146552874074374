import React from "react";
import { useHistory } from "react-router-dom";
import giaLogo from "../../assets/gia_logo.svg";
import AvatarIcon from "../../icons/Avatar";
import { useOktaAuth } from "@okta/okta-react";

function Header(props) {
  const history = useHistory();
  const [backgroundColor] = React.useState("black");
  const { authState } = useOktaAuth();

  const { email } = authState?.idToken?.claims || {};

  return (
    <div
      className="fixed left-0 top-0 w-full header-container flex justify-center items-center z-10"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="flex justify-between  items-center w-[100%] cursor-pointer">
        <div onClick={() => history.push("/")}>
          <img className="h-[120px] p-2" src={giaLogo} alt="GIA LOGO" />
        </div>
        <div className="flex justify-between  items-center gap-2 pr-8">
          <AvatarIcon className="w-[20px] h-[20px]" />
          <h1 style={{ color: "white" }} className="">
            {email}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Header;

import Select from "../Select";
import RadioGroup from "../RadioGroup";

export default (props) => {
  const { type, label, options, value, onChange, heading } = props;

  switch (type) {
    case "select": {
      return (
        <Select
          options={options}
          onChange={(val) => onChange(label, val)}
          value={value}
          label={heading}
          style={{ marginBottom: "12px" }}
        />
      );
    }
    case "radio": {
      return (
        <RadioGroup
          onChange={(e, val) => onChange(label, val, e)}
          value={value}
          label={label}
          captions={{
            left: { title: "Brand", subTitle: "Creative, Emotional" },
            right: { title: "Demand", subTitle: "Direct, rational" },
          }}
          options={options}
          style={{ marginBottom: "12px" }}
        />
      );
    }
    default:
      return <></>;
  }
};

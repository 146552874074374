import { styled, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"

export const AccordionContainer = styled('div')(({ theme }) => {
    const { mode } = theme.palette
    const { background_gray } = theme?.palette[mode]
    return {
        backgroundColor: background_gray,
        padding: '20px 35px',
        borderRadius: '18px',
        marginTop: '20px',
    }
})

export const AccordionComponent = styled(Accordion)(({ theme }) => {
    const { mode } = theme.palette
    const { background_gray } = theme?.palette[mode]
    return {
        backgroundColor: background_gray,
        padding: 0,
        boxShadow: 'none',
        backgroundImage: 'none',
    }
})

export const AccordionSummaryComponent = styled(AccordionSummary)(({ theme }) => {
    return {
        padding: 0,
        '.MuiAccordionSummary-content': {
            margin: 0,
        }
    }
})
export const AccordionDetailsComponent = styled(AccordionDetails)(({ theme }) => {
    return {
        padding: 0,
        '&.MuiAccordionSummary-content': {
            margin: 0
        }
    }
})

import { styled } from "@mui/material"

export default styled('button')(({ theme, style = {}, ...rest }) => {
    const { mode } = theme.palette
    const { gray, backgroundColor } = theme?.palette[mode]
    return {
        color: gray,
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        position: 'sticky',
        bottom: '-20px',
        left: 0,
        backgroundColor: backgroundColor,
        width: '100%',
        height: '50px',
        padding: '18px',
        ...style
    }
})
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch, useHistory } from "react-router-dom";
import oktaConfig from "./config/oktaConfig";
import { AppContextProvider } from "./context/AppContext";
import Form from "./pages/GIAForm";
import Brands from "./pages/Brands";
import FormCard from "./pages/GIACard";
import History from "./pages/History";
import { FormContextProvider } from "./context/FormContext";
import { HistoryContextProvider } from "./context/HistoryContext";

function App() {
  const oktaAuth = new OktaAuth({ ...oktaConfig });
  const history = useHistory();

  const RestoreOriginalUri = async (_oktaAuth, originalUri) => {
    const basepath = history.createHref({});
    const originalUriWithoutBasepath = originalUri.replace(basepath, "/");
    history.replace(
      toRelativeUrl(originalUriWithoutBasepath, window.location.origin)
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={RestoreOriginalUri}>
      <AppContextProvider>
        <FormContextProvider>
          <HistoryContextProvider>
            <ErrorBoundary FallbackComponent={<>Some Error Occurred</>}>
              <Switch>
                <>
                  <Route
                    exact
                    path="/login/callback"
                    component={LoginCallback}
                  />
                  <SecureRoute exact path="/">
                    <Brands />
                  </SecureRoute>
                  <SecureRoute exact path="/form">
                    <Form />
                  </SecureRoute>
                  <SecureRoute exact path="/form/results">
                    <FormCard />
                  </SecureRoute>
                  <SecureRoute exact path="/history" component={History} />
                </>
              </Switch>
            </ErrorBoundary>
          </HistoryContextProvider>
        </FormContextProvider>
      </AppContextProvider>
    </Security>
  );
}

export default App;

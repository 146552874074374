export default ({ fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="22"
      viewBox="0 0 114 22"
      fill="none"
    >
      <path
        fill={fill}
        d="M88.0569 2.17078H83.2338V6.32012H88.0638C91.1136 6.32012 93.5976 8.83065 93.5976 11.913C93.5976 14.9954 91.1136 17.5059 88.0638 17.5059H83.2338V21.6553H88.0569C93.3699 21.6553 97.6962 17.2827 97.6962 11.913C97.6962 6.54328 93.3699 2.17078 88.0569 2.17078Z"
      />
      <path
        fill={fill}
        d="M108.778 2.17078H113.601V6.32012H108.771C105.721 6.32012 103.237 8.83065 103.237 11.913C103.237 14.9954 105.721 17.5059 108.771 17.5059H113.601V21.6553H108.778C103.465 21.6553 99.1384 17.2827 99.1384 11.913C99.1384 6.54328 103.465 2.17078 108.778 2.17078Z"
      />
      <path
        fill={fill}
        d="M3.17309 2.77724C3.58019 2.55409 4.04939 2.4425 4.57379 2.4425C5.18789 2.4425 5.73989 2.59593 6.23669 2.90277C6.73349 3.20961 7.12679 3.64198 7.40969 4.19988C7.69949 4.76474 7.84439 5.41329 7.84439 6.1525C7.84439 6.89171 7.69949 7.54724 7.40969 8.11908C7.11989 8.69093 6.72659 9.13027 6.23669 9.44408C5.73989 9.7579 5.18789 9.91132 4.57379 9.91132C4.04939 9.91132 3.58709 9.79975 3.18689 9.58356C2.78669 9.36738 2.46239 9.08843 2.21399 8.76067V13.2378H0.406189V2.55409H2.21399V3.60014C2.44859 3.27238 2.76599 2.99343 3.17999 2.77027L3.17309 2.77724ZM5.73299 5.02277C5.55359 4.70198 5.32589 4.46488 5.03609 4.29751C4.74629 4.13014 4.43579 4.04645 4.09769 4.04645C3.75959 4.04645 3.46289 4.13014 3.17309 4.29751C2.88329 4.46488 2.64869 4.71593 2.47619 5.03672C2.29679 5.35751 2.21399 5.73409 2.21399 6.17343C2.21399 6.61277 2.30369 6.98935 2.47619 7.31014C2.65559 7.63093 2.88329 7.88198 3.17309 8.04935C3.46289 8.21672 3.76649 8.3004 4.09769 8.3004C4.42889 8.3004 4.74629 8.21672 5.03609 8.04238C5.32589 7.86804 5.56049 7.61698 5.73299 7.29619C5.91239 6.9754 5.99519 6.59185 5.99519 6.14553C5.99519 5.69922 5.90549 5.33659 5.73299 5.0158V5.02277Z"
      />
      <path
        fill={fill}
        d="M10.4802 9.45092C9.92821 9.14408 9.49351 8.70474 9.18301 8.13987C8.86561 7.575 8.71381 6.91947 8.71381 6.18026C8.71381 5.44105 8.87251 4.78553 9.19681 4.22066C9.52111 3.65579 9.96271 3.21645 10.5216 2.90961C11.0805 2.60276 11.7084 2.44934 12.3915 2.44934C13.0746 2.44934 13.7025 2.60276 14.2614 2.90961C14.8203 3.21645 15.2619 3.65579 15.5862 4.22066C15.9105 4.78553 16.0692 5.44105 16.0692 6.18026C16.0692 6.91947 15.9036 7.575 15.5724 8.13987C15.2412 8.70474 14.7927 9.14408 14.2269 9.45092C13.6611 9.75776 13.0332 9.91118 12.3363 9.91118C11.6394 9.91118 11.0253 9.75776 10.4733 9.45092H10.4802ZM13.2609 8.09802C13.5507 7.93066 13.7784 7.68658 13.9509 7.35881C14.1234 7.03105 14.2062 6.64052 14.2062 6.18026C14.2062 5.72 14.1234 5.3225 13.9509 5.00171C13.7784 4.67395 13.5507 4.42987 13.2747 4.2625C12.9918 4.09513 12.6882 4.01144 12.3639 4.01144C11.8533 4.01144 11.4255 4.19973 11.0736 4.58329C10.7217 4.95987 10.5423 5.49684 10.5423 6.18026C10.5423 6.86368 10.7148 7.40066 11.0598 7.77723C11.4048 8.15381 11.8326 8.34908 12.3363 8.34908C12.6606 8.34908 12.9711 8.26539 13.2609 8.09802Z"
      />
      <path
        fill={fill}
        d="M27.3231 2.56104L25.2324 9.79274H23.2797L21.9756 4.73682L20.6715 9.79274H18.705L16.6005 2.56104H18.4359L19.6986 8.07024L21.0717 2.56104H22.983L24.3285 8.0563L25.5912 2.56104H27.3231Z"
      />
      <path
        fill={fill}
        d="M34.9062 6.68934H29.676C29.7105 7.21934 29.8899 7.63776 30.2073 7.9446C30.5247 8.25144 30.9249 8.40487 31.3941 8.40487C32.0634 8.40487 32.5464 8.11197 32.8293 7.53316H34.782C34.575 8.23053 34.1817 8.80237 33.5952 9.24868C33.0087 9.695 32.2911 9.91816 31.4355 9.91816C30.7455 9.91816 30.1314 9.76474 29.5794 9.4579C29.0343 9.15105 28.6065 8.71171 28.3029 8.14684C27.9993 7.58197 27.8475 6.92645 27.8475 6.18724C27.8475 5.44803 27.9993 4.77855 28.3029 4.21368C28.6065 3.64881 29.0274 3.20947 29.5656 2.90961C30.1107 2.60276 30.7317 2.44934 31.4355 2.44934C32.1393 2.44934 32.7258 2.59579 33.264 2.89566C33.8022 3.18855 34.2231 3.60697 34.5198 4.15092C34.8165 4.6879 34.9683 5.31553 34.9683 6.01987C34.9683 6.26395 34.9476 6.48711 34.9131 6.69632L34.9062 6.68934ZM33.0846 5.58052C33.0777 5.07842 32.9121 4.68092 32.5878 4.38802C32.2635 4.09513 31.8633 3.94868 31.3803 3.94868C30.9249 3.94868 30.5385 4.09513 30.228 4.38802C29.9175 4.68092 29.7312 5.07842 29.6691 5.58052H33.0777H33.0846Z"
      />
      <path
        fill={fill}
        d="M38.9979 2.82625C39.3705 2.58217 39.7983 2.46362 40.2813 2.46362V4.38139H39.8052C39.2394 4.38139 38.8116 4.51388 38.5218 4.78586C38.232 5.05783 38.0871 5.52507 38.0871 6.19454V9.79994H36.2793V2.56822H38.0871V3.84441C38.3217 3.41204 38.6253 3.07033 38.9979 2.82625Z"
      />
      <path
        fill={fill}
        d="M48.0991 6.68934H42.8689C42.9034 7.21934 43.0828 7.63776 43.4002 7.9446C43.7176 8.25144 44.1178 8.40487 44.587 8.40487C45.2563 8.40487 45.7393 8.11197 46.0222 7.53316H47.9749C47.7679 8.23053 47.3746 8.80237 46.7881 9.24868C46.2016 9.695 45.484 9.91816 44.6284 9.91816C43.9384 9.91816 43.3243 9.76474 42.7723 9.4579C42.2272 9.15105 41.7994 8.71171 41.4958 8.14684C41.1922 7.58197 41.0404 6.92645 41.0404 6.18724C41.0404 5.44803 41.1922 4.77855 41.4958 4.21368C41.7994 3.64881 42.2203 3.20947 42.7585 2.90961C43.3036 2.60276 43.9246 2.44934 44.6284 2.44934C45.3322 2.44934 45.9187 2.59579 46.4569 2.89566C46.9951 3.18855 47.416 3.60697 47.7127 4.15092C48.0094 4.6879 48.1612 5.31553 48.1612 6.01987C48.1612 6.26395 48.1405 6.48711 48.106 6.69632L48.0991 6.68934ZM46.2775 5.58052C46.2706 5.07842 46.105 4.68092 45.7807 4.38802C45.4564 4.09513 45.0562 3.94868 44.5732 3.94868C44.1178 3.94868 43.7314 4.09513 43.4209 4.38802C43.1104 4.68092 42.9241 5.07842 42.862 5.58052H46.2706H46.2775Z"
      />
      <path
        fill={fill}
        d="M49.4376 4.20716C49.7274 3.64229 50.1207 3.20294 50.6175 2.90308C51.1143 2.59623 51.6732 2.44281 52.2873 2.44281C52.7496 2.44281 53.1981 2.54742 53.619 2.74965C54.0399 2.95189 54.378 3.22386 54.6264 3.56557V0.134521H56.4618V9.80005H54.6264V8.7261C54.4056 9.08176 54.0882 9.36768 53.6811 9.59084C53.274 9.80702 52.8048 9.9186 52.2735 9.9186C51.6732 9.9186 51.1212 9.76518 50.6175 9.45136C50.1138 9.13755 49.7274 8.69821 49.4376 8.12636C49.1478 7.55452 49.0029 6.89899 49.0029 6.15978C49.0029 5.42057 49.1478 4.77899 49.4376 4.21413V4.20716ZM54.378 5.03702C54.2055 4.71623 53.9709 4.47216 53.6811 4.30479C53.3913 4.13742 53.0739 4.05373 52.7358 4.05373C52.3977 4.05373 52.0872 4.13742 51.8043 4.30479C51.5214 4.47216 51.2868 4.70926 51.1143 5.03005C50.9349 5.35084 50.8521 5.72742 50.8521 6.15978C50.8521 6.59215 50.9418 6.97571 51.1143 7.30347C51.2937 7.63123 51.5214 7.88229 51.8112 8.05663C52.101 8.23097 52.4046 8.31466 52.7358 8.31466C53.067 8.31466 53.3844 8.23097 53.6811 8.0636C53.9709 7.89623 54.2055 7.65215 54.378 7.33136C54.5505 7.01057 54.6333 6.634 54.6333 6.18768C54.6333 5.74136 54.5505 5.36479 54.378 5.044V5.03702Z"
      />
      <path
        fill={fill}
        d="M64.4589 2.7701C64.866 2.55392 65.3352 2.44234 65.8596 2.44234C66.4737 2.44234 67.0257 2.59576 67.5225 2.9026C68.0193 3.20945 68.4126 3.64181 68.6955 4.19971C68.9784 4.7576 69.1302 5.41313 69.1302 6.15234C69.1302 6.89155 68.9853 7.54707 68.6955 8.11892C68.4057 8.69076 68.0124 9.1301 67.5225 9.44392C67.0257 9.75773 66.4737 9.91115 65.8596 9.91115C65.3283 9.91115 64.8591 9.80655 64.4589 9.59036C64.0587 9.37418 63.7344 9.10221 63.4929 8.7605V9.7926H61.6851V0.127075H63.4929V3.61392C63.7275 3.26523 64.0449 2.98628 64.4589 2.76313V2.7701ZM67.0188 5.0226C66.8394 4.70181 66.6117 4.46471 66.3219 4.29734C66.0321 4.12997 65.7216 4.04629 65.3835 4.04629C65.0454 4.04629 64.7487 4.12997 64.4589 4.29734C64.1691 4.46471 63.9345 4.71576 63.762 5.03655C63.5826 5.35734 63.4998 5.73392 63.4998 6.17326C63.4998 6.6126 63.5895 6.98918 63.762 7.30997C63.9414 7.63076 64.1691 7.88181 64.4589 8.04918C64.7487 8.21655 65.0523 8.30023 65.3835 8.30023C65.7147 8.30023 66.0321 8.21655 66.3219 8.04221C66.6117 7.86787 66.8463 7.61681 67.0188 7.29602C67.1982 6.97523 67.281 6.59168 67.281 6.14537C67.281 5.69905 67.1913 5.33642 67.0188 5.01563V5.0226Z"
      />
      <path
        fill={fill}
        d="M71.6487 2.56104L73.4979 7.61696L75.3678 2.56104H77.2929L72.8631 13.2168H70.938L72.4905 9.61143L69.6201 2.56104H71.6487Z"
      />
    </svg>
  );
};

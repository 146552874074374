import { styled, Divider } from '@mui/material'

export default  styled(Divider)(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { light_gray } = theme?.palette[mode]
    return {
        margin: '12px 0',
        backgroundColor: light_gray,
        height: '2px',
    }
})
const ShareIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask x="0" y="0" width="40" height="40">
      <rect width="40" height="40" fill="#D9D9D9" />
    </mask>
    <g>
      <path
        d="M9.44454 38.3332C8.69454 38.3332 8.04399 38.0578 7.49288 37.5069C6.94204 36.9558 6.66663 36.3053 6.66663 35.5553V15.3053C6.66663 14.5553 6.94204 13.9048 7.49288 13.354C8.04399 12.8032 8.69454 12.5278 9.44454 12.5278H15.8054V15.3053H9.44454V35.5553H30.5554V15.3053H24.1387V12.5278H30.5554C31.3054 12.5278 31.9559 12.8032 32.507 13.354C33.0579 13.9048 33.3333 14.5553 33.3333 15.3053V35.5553C33.3333 36.3053 33.0579 36.9558 32.507 37.5069C31.9559 38.0578 31.3054 38.3332 30.5554 38.3332H9.44454ZM18.5833 25.9165V7.01359L15.25 10.3469L13.2779 8.36109L19.972 1.6665L26.6666 8.36109L24.6945 10.3469L21.3612 7.01359V25.9165H18.5833Z"
        fill="#AAAAAA"
      />
    </g>
  </svg>
);

export default ShareIcon;

const radioOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const campaignOptions = [
  { label: "Product Launch", value: "Product Launch" },
];

const audienceOptions = [
  { label: "Luxury Explorer", value: "Luxury Explorer" },
  { label: "Ego-centric Gamer", value: "Ego-centric Gamer" },
  { label: "Status Seeker", value: "Status Seeker" },
  { label: "The Worrier", value: "The Worrier" }
];

const inputs = [
  {
    label: "Campaign",
    heading: "Campaign",
    type: "select",
    options: campaignOptions,
  },
  { label: "Products", heading: "Products", type: "select", options: [] },
  {
    label: "Performance Objective",
    heading: "Performance Objective",
    type: "radio",
    options: radioOptions,
  },
  {
    label: "Target Audience",
    heading: "Target Audience",
    type: "select",
    options: audienceOptions,
  },
  { label: "channels", heading: "Channel", type: "select", options: [] },
  { label: "components", heading: "Component", type: "select", options: [] },
  { label: "Format", heading: "Format", type: "select", options: [] },
];

export { radioOptions, campaignOptions, inputs };

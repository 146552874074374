const UnpinIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      x='0'
      y='0'
      width='24'
      height='24'
    >
      <rect width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_9_1115)'>
      <path
        d='M15.0818 15.4251L15.1879 18.2889L14.0093 19.4675L10.3559 15.814L6.10141 20.0685L4.92301 20.0685L4.92301 18.8901L9.1775 14.6356L5.52405 10.9822L6.70262 9.8036L9.4957 9.83896L15.07 4.26466L14.245 3.43964L15.4235 2.26107L22.6597 9.49725L21.4812 10.6758L20.6561 9.8508L15.0818 15.4251Z'
        fill='#D2D2D2'
      />
    </g>
  </svg>
);

export default UnpinIcon;

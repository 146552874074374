const UnlikeIcon = ({ fill = "#AAAAAA" }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_9_1628"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="40"
      height="40"
    >
      <rect width="40" height="40" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_9_1628)">
      <path
        d="M10.0554 5H28.5V26.4446L16.8887 38.3333L15.1112 36.7779C14.9351 36.6204 14.7984 36.4028 14.7012 36.125C14.604 35.8472 14.5554 35.5371 14.5554 35.1946V34.7221L16.4166 26.4446H4.52789C3.78705 26.4446 3.13886 26.1668 2.5833 25.6113C2.02775 25.0557 1.74997 24.4075 1.74997 23.6667V20.2821C1.74997 20.0849 1.74539 19.8797 1.73622 19.6667C1.72677 19.4536 1.75914 19.2499 1.8333 19.0554L6.99997 7.08333C7.24775 6.50472 7.66066 6.01278 8.23872 5.6075C8.81677 5.2025 9.42233 5 10.0554 5ZM25.7221 7.77792H9.69455L4.52789 19.9721V23.6667H19.8887L17.6666 33.6388L25.7221 25.2779V7.77792ZM28.5 26.4446V23.6667H33.8612V7.77792H28.5V5H36.6387V26.4446H28.5Z"
        fill={fill}
      />
    </g>
  </svg>
);
export default UnlikeIcon;

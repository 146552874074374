import { styled } from '@mui/material'

export default styled('form')(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { backgroundGray } = theme?.palette[mode]
    return {
        width: '690px',
        height: 'fit-content',
        backgroundColor: backgroundGray,
        padding: '36px 35px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '18px',
    }
})
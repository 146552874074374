import { CircularProgress } from "@mui/material"

export default ({children, isLoading}) => {
    if(isLoading)
    return (
        <div className="relative h-full">
            <div className="fixed flex w-full h-full flex justify-center pt-[25%] bg-slate-200 opacity-30 z-10">
                <CircularProgress color="inherit" />
            </div>
            {children && children}
        </div>
    )
    else
    return children
}
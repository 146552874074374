import axios from "axios";
// import chartData from "../pages/History/data";

export const getChatHistoryService = async ({
  brand,
  rowsPerPage = 10,
  pageNumber = 0,
  historyQuery,
  config,
}) => {
  try {
    const { email, userId, oktaToken, url, apiKey } = config;
    const response = await axios.post(
      url,
      {
        brand_name: brand,
        search_text:
          !historyQuery || historyQuery.length === 0 ? "" : historyQuery,
        page_size: rowsPerPage,
        page_number: pageNumber,
        type: "Listing",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaToken}`,
          "X-User-Email": email,
          "X-User-Aud": userId,
          "ocp-apim-subscription-key": apiKey,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateFeedbackService = async ({
  brand,
  feed_back_action,
  copy_id,

  config,
}) => {
  try {
    const { email, userId, oktaToken, url, apiKey } = config;
    const response = await axios.post(
      url,
      {
        brand_name: brand,
        feed_back_action: feed_back_action,
        id: copy_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaToken}`,
          "X-User-Email": email,
          "X-User-Aud": userId,
          "ocp-apim-subscription-key": apiKey,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

import { styled, Divider, Button } from "@mui/material"
import InputBase from '@mui/material/InputBase';
import SearchIcon from "../../icons/SearchIcon";
import IconButton from "@mui/material/IconButton";
import { useAppContext } from "../../context/AppContext";
import ClearTextIcon from "../../icons/ClearTextIcon";

const ClearTextButton = styled(Button)(({ theme }) => {
    return {
        width: '20px', height: '15px', borderRadius: '25px', padding: 0, position: 'absolute',
        right: '85px', top: '13px', minWidth: '10px', minHeight: '20px',
        '&:hover': {
            backgroundColor: 'gray'
        }
    }
})

const SearchInputWrapper = styled('div')(({theme})=>{
    return {
        display: 'flex',
        flexDirection:'column',
        width: '100%',
    }
})

const Search = styled("div")(({ theme, style = {}, ...rest }) => {
    const { mode } = theme.palette
    const { gray, background_gray } = theme?.palette[mode]
    return {
        position: 'relative',
        border: `2px solid ${gray}`,
        borderRadius: '35px',
        backgroundColor: background_gray,
        width: '100%',
        height: '50px',
        display: 'flex',
        ...style,
    }
});

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    width: '15%',
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
}));

export const SearchInput = (props) => {
    const { themeMode, theme } = useAppContext()
    const { iconGray } = theme?.palette[themeMode]
    const {
        placeholder,
        onChange,
        value,
        Icon,
        style = {},
        hideClearIcon = false,
        errorLabel = "",
    } = props
    console.log('SearchInput', errorLabel)
    return (
        <SearchInputWrapper>
        <Search style={style} >
            <InputComponent
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={{ minWidth: '92%', flex: 1, height: '100%', margin: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0, border: 'none' }}
            />
            {
                !hideClearIcon && value.length > 0 && <ClearTextButton onClick={() => onChange({ target: { value: "" } })}>
                    <ClearTextIcon fill={iconGray} />
                </ClearTextButton>
            }
            <Divider orientation="vertical" sx={{ margin: '5px 0', backgroundColor: iconGray, height: 'auto' }} />
            <SearchIconWrapper>
                <IconButton>
                    {Icon ? <Icon fill={iconGray} /> : <SearchIcon fill={iconGray} />}
                </IconButton>
            </SearchIconWrapper>
        </Search>
            {errorLabel.length > 0 && <div>{errorLabel}</div>}
        </SearchInputWrapper>
    );
}



const InputComponent = styled(InputBase)(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { gray, background_gray } = theme?.palette[mode]
    return {
        border: `2px solid ${gray}`,
        borderRadius: '35px',
        backgroundColor: background_gray,
        width: '690px',
        height: '50px',
        paddingLeft: '22px',
    }
})


export default (props) => {
    return (
        <InputComponent {...props} />
    )
}

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  // brandOptions as brandDropdownOptions,
  inputs,
  // chatsData as chatHistory,
} from "../constants";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

const FormContext = createContext();

const FormContextProvider = ({ children }) => {
  const history = useHistory();

  const [quickStartQuery, setQuickStartQuery] = useState("");
  const [brand, setBrand] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [inputItems, setInputItems] = useState([]);
  const [formState, setFormState] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [generatedCopy, setGeneratedCopy] = useState("");
  const [connectionId, setConnectionId] = useState("");
  const [streamingResponse, setStreamingResponse] = useState("");
  const [streamingStepCount, setStreamingStepCount] = useState(0);
  const [giaFormInputQuery, setGiaFormInputQuery] = useState("");
  const [copyId, setCopyId] = useState("");
  const { getClientAccessUrl } = useAppContext();

  const initializeInputs = () => {
    if (Array.isArray(inputItems) && inputs.length > 0) {
      setInputItems(inputs);
      const state = {};
      inputs.forEach((ip) => {
        state[ip.label] = "";
      });
      setFormState(state);
    }
  };

  useEffect(() => {
    initializeInputs();
  }, [inputs]); // eslint-disable-line

  useEffect(() => {
    setIsFormValid(true);
    inputItems.forEach((item) => {
      if (`${formState[item.label]}`.length === 0) {
        setIsFormValid(false);
        return;
      }
    });
  }, [formState]); // eslint-disable-line

  const resetFormData = () => {
    // reset quick query input
    setQuickStartQuery("");
    // TODO: reset form data
  };

  const submitQuickQuery = async () => {
    if (quickStartQuery.length > 0) {
      // call service api and .then redirect
      await getClientAccessUrl();
      setGeneratedCopy("");
      history.push("/form/results");
      //resetFormData()
    }
  };

  const handleSubmitForm = async () => {
    if (isFormValid) {
      // call service api and .then redirect
      await getClientAccessUrl();
      setGeneratedCopy("");
      history.push("/form/results");
      //resetFormData()
    }
  };

  return (
    <FormContext.Provider
      value={{
        quickStartQuery,
        setQuickStartQuery,
        brand,
        setBrand,
        brandOptions,
        setBrandOptions,
        inputItems,
        setInputItems,
        formState,
        setFormState,
        isFormValid,
        setIsFormValid,
        // chatsData,
        // setChatsData,
        resetFormData,
        submitQuickQuery,
        handleSubmitForm,
        generatedCopy,
        setGeneratedCopy,
        connectionId,
        setConnectionId,
        streamingResponse,
        setStreamingResponse,
        streamingStepCount,
        setStreamingStepCount,
        giaFormInputQuery,
        setGiaFormInputQuery,
        copyId,
        setCopyId,
        initializeInputs,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useFormContext = () => {
  return useContext(FormContext);
};

export { useFormContext, FormContext, FormContextProvider };

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Divider } from "@mui/material";
import Layout from "../../components/Layout";
// import BrandImageBackground from "./BrandImageBackground";
// import AddIcon from "../../icons/AddIcon";
import BrandCard from "./BrandCard";

import { useAppContext } from "../../context/AppContext";
import { useFormContext } from "../../context/FormContext";

import { Enum } from "../../constants";

const { PINNED_IMAGES } = Enum;

const Brands = (props) => {
  const history = useHistory();
  const { setSelectedBrand, appConfig } = useAppContext();
  const { setBrand } = useFormContext();

  const [pinnedImages, setPinnedImages] = useState(() =>
    JSON.parse(localStorage.getItem(PINNED_IMAGES) || "[]")
  );
  const [allClients, setAllClient] = useState(appConfig);

  useEffect(() => {
    setAllClient(appConfig);
  }, [appConfig]);

  const handlePinClick = async (client) => {
    if (client?.id && client.id.length > 0) {
      if (pinnedImages.indexOf(client.id) < 0) {
        const tempList = [client.id, ...pinnedImages];
        await localStorage.setItem(PINNED_IMAGES, JSON.stringify(tempList));
        setPinnedImages(tempList);
      } else {
        const tempList = pinnedImages.filter((el) => el !== client.id);
        await localStorage.setItem(PINNED_IMAGES, JSON.stringify(tempList));
        setPinnedImages(tempList);
      }
    }
  };

  const handleCardClick = (e, client) => {
    e.stopPropagation();
    if (e.target.tagName === "path" || e.target.tagName === "svg") {
      handlePinClick(client);
    } else {
      setSelectedBrand(client);
      setBrand(client?.id);
      history.push("/form");
    }
  };

  const pinned = allClients.filter((el) => pinnedImages.indexOf(el.id) >= 0);
  const unPinned = allClients.filter((el) => pinnedImages.indexOf(el.id) < 0);

  return (
    <Layout showSideMenu={false}>
      <div className="relative">
        <div className="text-[40px] mt-6 flex justify-center items-center">
          <p>Which client can Gia help you write for today?</p>
        </div>

        {pinnedImages.length > 0 && (
          <>
            <p className="text-base mt-10 pl-4"> Your Top Clients</p>
            <Divider variant="middle" />
          </>
        )}
        <div className="grid grid-cols-3 gap-6 p-6 mb-12">
          {pinned?.map((client) => (
            <BrandCard
              key={client?.logo}
              src={client?.logo}
              heading={client.name}
              onClick={(e) => handleCardClick(e, client)}
              type="pinned"
            />
          ))}
        </div>
        {unPinned.length ? (
          <>
            <div>
              <p className="pl-4">All Clients</p>
              <Divider variant="middle" />
            </div>
            <div className="grid grid-cols-3 gap-6 p-6 mb-12 ">
              {unPinned?.map((client) => (
                <BrandCard
                  key={client?.logo}
                  src={client?.logo}
                  heading={client.name}
                  onClick={(e) => handleCardClick(e, client)}
                  type="unPinned"
                />
              ))}
              {/* <BrandImageBackground className="h-[246px] p-8 rounded-3xl flex justify-center items-center">
              <AddIcon />
            </BrandImageBackground> */}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default Brands;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { styled, Select } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import DropdownIcon from "../../icons/DropdownIcon";

const SelectComponent = styled(Select)(({ theme, value, ...rest }) => {
  const { mode } = theme.palette;
  const { black, mid_gray, backgroundColor } = theme?.palette[mode];
  return {
    width: "100%",
    height: "50px",
    position: "relative",
    color: value.length === 0 ? mid_gray : black,
    fontFmily: "Poppins",
    fontSize: value.length === 0 ? "14px" : "16px",
    fontStyle: "normal",
    fontWeight: value.length === 0 ? 400 : 600,
    lineHeight: "normal",
    margin: 0,
    border: "none",
    backgroundColor,
    borderRadius: "12px",
  };
});

const Label = styled("div")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { gray } = theme?.palette[mode];
  return {
    color: gray,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginBottom: "6px",
  };
});

export default function SelectLabels({
  value = "",
  label,
  placeHolder = "-Select-",
  helperText,
  onChange,
  options = [],
  style = {},
}) {
  const { themeMode, theme } = useAppContext();
  const { select_border } = theme?.palette[themeMode];
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  return (
    <FormControl sx={{ minWidth: 120, width: "100%", ...style }}>
      {label && <Label>{label}</Label>}
      <SelectComponent
        value={value}
        onChange={handleChange}
        displayEmpty
        IconComponent={() => <DropdownIcon fill={select_border} />}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: select_border,
            borderRadius: "12px",
            width: "100%",
          },
        }}
      >
        <MenuItem value="">{placeHolder}</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.name || option.label}
          </MenuItem>
        ))}
      </SelectComponent>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

/* 
<Select
    options={[{ label: 'TEN', value: 10 }, { label: 'TWENTY', value: 20 }, { label: 'THIRTY', value: 30 }]}
    onChange={(value) => { }}
    value={"value"}
/> 
*/

const LikeIcon = ({ fill = "#AAAAAA" }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_9_1627"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="40"
      height="40"
    >
      <rect width="40" height="40" fill={"#D9D9D9"} />
    </mask>
    <g mask="url(#mask0_9_1627)">
      <path
        d="M29.8888 34.9998H11.4446V13.5553L23.0554 1.6665L24.8333 3.22192C25.0092 3.37942 25.1457 3.59706 25.2429 3.87484C25.3401 4.15262 25.3888 4.46275 25.3888 4.80525V5.27775L23.5279 13.5553H35.4167C36.1575 13.5553 36.8057 13.833 37.3613 14.3886C37.9168 14.9441 38.1946 15.5923 38.1946 16.3332V19.7178C38.1946 19.915 38.1992 20.1201 38.2083 20.3332C38.2175 20.5462 38.1851 20.75 38.1113 20.9444L32.9446 32.9165C32.6968 33.4951 32.2839 33.9871 31.7058 34.3923C31.1278 34.7973 30.5221 34.9998 29.8888 34.9998ZM14.2221 32.2219H30.25L35.4167 20.0278V16.3332H20.0554L22.2779 6.36109L14.2221 14.7219V32.2219ZM11.4446 13.5553V16.3332H6.08334V32.2219H11.4446V34.9998H3.30542V13.5553H11.4446Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default LikeIcon;

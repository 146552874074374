const keyTypes = {
  campain: "select",
  product: "select",
  "target audience": "select",
  channel: "select",
  component: "select",
  format: "select",
  "performance objective": "radio",
};
const brandOptions = [
  { label: "SoFi", value: 10 },
  { label: "Cox", value: 20 },
  { label: "Culligan", value: 30 },
];
const selectOptions = [
  { label: "TEN", value: 10 },
  { label: "TWENTY", value: 20 },
  { label: "THIRTY", value: 30 },
];
const radioOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

const inputs = [
  { label: "Campaign", type: "select", options: [] },
  { label: "Product", type: "select", options: [] },
  { label: "Performance Objective", type: "radio", options: [] },
  { label: "Target Audience", type: "select", options: [] },
  { label: "Channel", type: "select", options: [] },
  { label: "Component", type: "select", options: [] },
  { label: "Format", type: "select", options: [] },
];

const chatsData = [
  {
    date: "04/12/2024",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam scelerisque urna et feugiat ullamcorper.",
  },
  {
    date: "04/12/2024",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam scelerisque urna et feugiat ullamcorper. Fusce dictum semper erat, a luctus justo aliquam sit amet.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    date: "04/12/2024",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam scelerisque urna et feugiat ullamcorper. Fusce dictum semper erat, a luctus justo aliquam sit amet.",
  },
  {
    date: "04/12/2024",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam scelerisque urna et feugiat ullamcorper.",
  },
];

const clients = [
  {
    src: `${process.env.REACT_APP_BLOB_URL}gia-assets/sofi.png${process.env.REACT_APP_SAS_TOKEN}`,
    heading: "SoFi",
    value: 4,
  },
];

const Enum = {
  PINNED_IMAGES: 'pinnedImages',
  PINNED: "pinned",
  UNPINNED: 'unPinned',
  FALLBACK_QUERY_MIN_CHAR: 3,
}

export {
  keyTypes,
  brandOptions,
  selectOptions,
  radioOptions,
  inputs,
  chatsData,
  clients,
  Enum,
};

import { styled, Button } from "@mui/material";

const GradientButton = styled(Button)(
  ({ theme, disabled = false, style = {}, ...rest }) => {
    const { mode } = theme.palette;
    const { pureWhite, color } = theme?.palette[mode];

    const disabledStyle = disabled
      ? {
          color: `${color} !important`,
        }
      : {};
    return {
      display: "flex",
      width: "215px",
      height: "50px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "25px",
      background: "linear-gradient(83deg, #4B00FF, #FA6968)",
      color: pureWhite,
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "1.92px",
      textTransform: "uppercase",
      ...disabledStyle,
      ...style,
    };
  }
);

const ButtonLabel = styled("div")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { backgroundColor } = theme?.palette[mode];

  return {
    backgroundColor,
  };
});

export default ({
  label = "GIA GENERATE",
  style = {},
  disabled,
  onClick,
  ...rest
}) => {
  return (
    <GradientButton disabled={disabled} style={style} onClick={onClick}>
      {!disabled ? (
        <>{label}</>
      ) : (
        <ButtonLabel className="rounded-full w-full h-full flex justify-center items-center">
          {label}
        </ButtonLabel>
      )}
    </GradientButton>
  );
};

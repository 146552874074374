export default ({ fill = "#D9D9D9" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="40"
      viewBox="0 0 41 40"
      storke={fill}
    >
      <mask x="0" y="0" width="41" height="40">
        <rect x="0.5" width="40" height="40" fill={fill} />
      </mask>
      <g>
        <path
          fill={fill}
          d="M19.1112 23.3334H21.8887V18.0555H27.1666V15.278H21.8887V10H19.1112V15.278H13.8333V18.0555H19.1112V23.3334ZM3.83331 36.6667V6.11129C3.83331 5.36129 4.10873 4.71074 4.65956 4.15962C5.21067 3.60879 5.86123 3.33337 6.61123 3.33337H34.3887C35.1387 3.33337 35.7893 3.60879 36.3404 4.15962C36.8912 4.71074 37.1666 5.36129 37.1666 6.11129V27.2221C37.1666 27.9721 36.8912 28.6227 36.3404 29.1738C35.7893 29.7246 35.1387 30 34.3887 30H10.5L3.83331 36.6667ZM9.3054 27.2221H34.3887V6.11129H6.61123V30.0696L9.3054 27.2221Z"
        />
      </g>
    </svg>
  );
};

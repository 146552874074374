import { styled, Paper } from "@mui/material";

export default styled(Paper)(({ theme, style = {}, ...rest }) => {
    const { mode } = theme.palette
    const { background_gray, gray } = theme?.palette[mode]
    return ({
        backgroundColor: background_gray,
        color: gray,
        border: 'none',
        borderRadius: 0,
        boxShadow: 'unset',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '25px',
        cursor: 'pointer',
        ...style,
    })
});
import React from 'react';
import { Button, styled } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import SunIcon from '../../icons/SunIcon';
import MoonIcon from '../../icons/MoonIcon';

const ThemeToggleSwitch = styled(Button)(({ theme, ...rest }) => {
    const { mode } = theme.palette
    const { black } = theme?.palette[mode]
    return ({
        color: black,
        minWidth: 'fit-content',
        height: '44px',
        padding: 0,
    })
});

const ThemeSwitch = ({style={}, className=""}) => {
    const { toggleTheme, /*themeMode*/ } = useAppContext();
    return (
        <div 
        direction='row' 
        spacing={0} 
        className={'bottom-0 left-6 w-full flex justify-center'+(typeof className === 'string' ? className : '')}
        style={{...style}}
        >
            <ThemeToggleSwitch disableRipple onClick={toggleTheme}>
                <SunIcon />
            </ThemeToggleSwitch>
            <ThemeToggleSwitch  disableRipple onClick={toggleTheme}>
                <MoonIcon />
            </ThemeToggleSwitch>
        </div>
    )
}

export default ThemeSwitch

export default ({ fill = "#D2D2D2" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <mask x="0" y="0" width="32" height="32">
                <rect width="32" height="32" fill={fill} />
            </mask>
            <g>
                <path d="M2.63899 10.3055L4.69466 8.27222L15.9723 19.5499L27.2503 8.27222L29.3057 10.3056L15.9723 23.6389L2.63899 10.3055Z" fill={fill} />
            </g>
        </svg>
    )
}
export default props => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
            <mask x='0' y='0' width='44' height='44'>
                <rect width='44' height='44' fill='#D9D9D9' />
            </mask>
            <g >
                <path d='M22 25.9393C23.0898 25.9393 24.0188 25.5552 24.787 24.787C25.5552 24.0188 25.9393 23.0898 25.9393 22C25.9393 20.9102 25.5552 19.9812 24.787 19.213C24.0188 18.4448 23.0898 18.0607 22 18.0607C20.9102 18.0607 19.9812 18.4448 19.213 19.213C18.4448 19.9812 18.0607 20.9102 18.0607 22C18.0607 23.0898 18.4448 24.0188 19.213 24.787C19.9812 25.5552 20.9102 25.9393 22 25.9393ZM22 27.9091C20.3652 27.9091 18.9716 27.333 17.8193 26.1807C16.667 25.0284 16.0909 23.6348 16.0909 22C16.0909 20.3652 16.667 18.9716 17.8193 17.8193C18.9716 16.667 20.3652 16.0909 22 16.0909C23.6348 16.0909 25.0284 16.667 26.1807 17.8193C27.333 18.9716 27.9091 20.3652 27.9091 22C27.9091 23.6348 27.333 25.0284 26.1807 26.1807C25.0284 27.333 23.6348 27.9091 22 27.9091ZM13.7273 22.9847H9V21.0153H13.7273V22.9847ZM35 22.9847H30.2727V21.0153H35V22.9847ZM21.0153 13.7273V9H22.9847V13.7273H21.0153ZM21.0153 35V30.2727H22.9847V35H21.0153ZM15.5 16.8198L12.5357 13.9539L13.9438 12.5062L16.7802 15.4607L15.5 16.8198ZM30.0757 31.4743L27.1902 28.5097L28.5198 27.1607L31.4643 30.0461L30.0757 31.4743ZM27.1607 15.4802L30.0461 12.5357L31.4938 13.9243L28.5591 16.8L27.1607 15.4802ZM12.5257 30.0562L15.4705 27.1705L16.8393 28.5198L13.9539 31.4643L12.5257 30.0562Z' fill='#5A5A5A' />
            </g>
        </svg>
    )
}
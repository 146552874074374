export default ({ fill = '#5A5A5A', style = { marginRight: '10px' } }) => {
    return (
        <svg style={style} xmlns='http://www.w3.org/2000/svg' width='34' height='24' viewBox='0 0 24 24' fill={fill}>
            <mask x='0' y='0' width='24' height='24'>
                <rect width='24' height='24' fill={fill} />
            </mask>
            <g>
                <path d='M12 15.375L6 9.37498L7.4 7.97498L12 12.575L16.6 7.97498L18 9.37498L12 15.375Z' fill={fill} />
            </g>
        </svg>
    )
}
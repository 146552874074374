const CopyIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask x="0" y="0" width="40" height="40">
      <rect width="40" height="40" fill="#D9D9D9" />
    </mask>
    <g>
      <path
        d="M13.3333 31.1114C12.5833 31.1114 11.9329 30.8359 11.3821 30.2847C10.831 29.7339 10.5554 29.0835 10.5554 28.3335V6.11141C10.5554 5.36141 10.831 4.71086 11.3821 4.15975C11.9329 3.60891 12.5833 3.3335 13.3333 3.3335H30.5554C31.3054 3.3335 31.956 3.60891 32.5071 4.15975C33.0579 4.71086 33.3333 5.36141 33.3333 6.11141V28.3335C33.3333 29.0835 33.0579 29.7339 32.5071 30.2847C31.956 30.8359 31.3054 31.1114 30.5554 31.1114H13.3333ZM13.3333 28.3335H30.5554V6.11141H13.3333V28.3335ZM7.77792 36.6668C7.02792 36.6668 6.37736 36.3914 5.82625 35.8406C5.27542 35.2895 5 34.6389 5 33.8889V8.88891H7.77792V33.8889H27.7779V36.6668H7.77792Z"
        fill="#AAAAAA"
      />
    </g>
  </svg>
);

export default CopyIcon;

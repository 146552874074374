import moment from "moment"

export const parseHistoryData = ({data=[], chatHistory={}}) => {
    try {
        const monthlyData = {...chatHistory}
        const rawData = []
        data.forEach((el,i) => {
            const timeStamp = el._ts ? (el._ts * 1000) : parseInt(el.timestamp || el.ts)
            const currentMonth = moment().format('MMMM')
            const readableTimeStamp =  moment(timeStamp).format('MM-DD-YYYY')
            if(timeStamp){
                const monthValue = moment(timeStamp).format('MMMM')
                const month = monthValue === currentMonth ? 'Recent' : monthValue
                if(!monthlyData[month]){
                    monthlyData[month] = []
                }
                monthlyData[month].push({...el, month, readableTimeStamp})
                rawData.push({...el, month, date: readableTimeStamp, message: el.copy || ''})
            }
        })
        return {monthlyData, rawData}
    } catch (error) {
        console.log('parseHistoryData error', error)
    }
}
export default props => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
            <mask x='0' y='0' width='44' height='44'>
                <rect width='44' height='44' fill='#D9D9D9' />
            </mask>
            <g >
                <path d='M22 32C19.2222 32 16.8611 31.0278 14.9167 29.0833C12.9722 27.1389 12 24.7778 12 22C12 19.2222 12.9722 16.8611 14.9167 14.9167C16.8611 12.9722 19.2222 12 22 12C22.1852 12 22.375 12.0062 22.5694 12.0186C22.7639 12.0308 22.9877 12.0494 23.2408 12.0742C22.5432 12.6482 21.9954 13.3689 21.5972 14.2361C21.1991 15.1033 21 16.0246 21 17C21 18.6667 21.5833 20.0833 22.75 21.25C23.9167 22.4167 25.3333 23 27 23C27.9815 23 28.9044 22.8102 29.7686 22.4306C30.6327 22.0509 31.3518 21.5308 31.9258 20.8703C31.9506 21.0988 31.9692 21.301 31.9814 21.4769C31.9938 21.6529 32 21.8272 32 22C32 24.7778 31.0278 27.1389 29.0833 29.0833C27.1389 31.0278 24.7778 32 22 32ZM22 30.1481C23.8889 30.1481 25.5494 29.5818 26.9814 28.4492C28.4136 27.3164 29.3519 25.9475 29.7964 24.3425C29.3642 24.5092 28.9094 24.6357 28.4322 24.7222C27.9548 24.8087 27.4774 24.8519 27 24.8519C24.8248 24.8519 22.9724 24.087 21.4428 22.5572C19.913 21.0276 19.1481 19.1752 19.1481 17C19.1481 16.5802 19.1882 16.1388 19.2686 15.6758C19.3488 15.2129 19.4845 14.7036 19.6758 14.1481C17.9845 14.679 16.591 15.6697 15.4953 17.1203C14.3997 18.571 13.8519 20.1976 13.8519 22C13.8519 24.2593 14.6451 26.1821 16.2314 27.7686C17.8179 29.3549 19.7407 30.1481 22 30.1481Z' fill='#AAAAAA' />
            </g>
        </svg>
    )
}

const PinIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_9_1119'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='24'
      height='24'
    >
      <rect width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_9_1119)'>
      <path
        d='M15.0878 15.4279L15.1938 18.2917L14.0153 19.4703L10.3618 15.8168L6.10733 20.0713L4.92893 20.0713L4.92893 18.8929L9.18342 14.6384L5.52997 10.985L6.70855 9.80641L9.50162 9.84176L15.0641 4.27931L14.2391 3.45429L15.4176 2.27572L22.6538 9.5119L21.4752 10.6905L20.6502 9.86545L15.0878 15.4279ZM8.33489 11.4328L13.4968 16.5946L13.4496 14.709L19.4716 8.68688L16.2426 5.45788L10.2206 11.48L8.33489 11.4328Z'
        fill='#D2D2D2'
      />
    </g>
  </svg>
);

export default PinIcon;

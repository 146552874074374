export const customPallete = (mode) => {
  const palette = {
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            light: {
              backgroundColor: "#ffffff",
              color: "#000000",
              backgroundGray: "#F5F5F5",
              iconGray: "#5A5A5A",
              iconGrayNew: "#000000",

              white: "ffffff",
              black: "#000000",
              deep_purple: "#3B0035",
              vivid_purple: "#4B00FF",
              light_purple: "#8973FF",
              brick_red: "#D23C50",
              coral: "#FA6968",
              light_coral: "#FC8281",
              gray: "#5A5A5A",
              mid_gray: "#AAAAAA",
              light_gray: "#D2D2D2",
              background_gray: "#F5F5F5",

              select_border: "#AAAAAA",
              pureWhite: "#ffffff",
              fill: "#000000",
            },
          }
        : mode === "dark"
        ? {
            // palette values for dark mode
            dark: {
              backgroundColor: "#000000",
              color: "#ffffff",
              backgroundGray: "#202020",
              iconGray: "#D9D9D9",
              iconGrayNew: "#D9D9D9",

              white: "000000",
              black: "#ffffff",
              deep_purple: "#3B0035",
              vivid_purple: "#4B00FF",
              light_purple: "#8973FF",
              brick_red: "#D23C50",
              coral: "#FA6968",
              light_coral: "#FC8281",
              gray: "#D2D2D2",
              mid_gray: "#AAAAAA",
              light_gray: "#5A5A5A",
              background_gray: "#202020",

              select_border: "#ffffff",
              pureWhite: "#ffffff",
              fill: "#ffffff",
            },
          }
        : {}),
    },
  };
  return palette;
};

import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { customPallete } from "../config/themeConfig";
import getAppConfig from "../services/appConfigFunc";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";

const AppContext = createContext();

const THEME_MODE_KEY = "themeMode";
const SELECTED_BRAND_KEY = "selectedBrand";

const AppContextProvider = ({ children }) => {
  let t;
  const [appConfig, setAppConfig] = useState([]);
  const [globalAppConfig, setGlobalAppConfig] = useState({});
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [pubsubClientAccessUrl, setPubsubClientAccessUrl] = useState("");
  const [isAppConfigLoading, setIsAppConfigLoading] = useState(false);
  const [isPubSubLoading, setIsPubSubLoading] = useState(false);
  const [appErrorMessage, setAppErrorMessageState] = useState("");

  const setAppErrorMessage = (error, time = 3000) => {
    console.log("set app error message", error);
    if (!error || error?.length === 0) {
      clearTimeout(t);
      setAppErrorMessageState(error);
    }
    setAppErrorMessageState(error);

    t = setTimeout(() => {
      console.log("timeout called");
      setAppErrorMessageState("");
    }, 20000);
  };

  const { authState } = useOktaAuth();

  const [selectedBrand, setSelectedBrand] = useState(() => {
    const savedBrand = localStorage.getItem(SELECTED_BRAND_KEY);
    return savedBrand !== "undefined" && savedBrand
      ? JSON.parse(savedBrand)
      : null;
  });

  const oktaToken = JSON.parse(sessionStorage.getItem("okta-token-storage"))
    ?.idToken?.idToken;

  useEffect(() => {
    if (!oktaToken) return
    setIsAppConfigLoading(true);
    getAppConfig(oktaToken)
      .then((appConfigRaw) => {
        const parsedLayoutConfig = JSON.parse(appConfigRaw);
        const { GiaGlobalAppConfig, GiaUIAppConfig } = parsedLayoutConfig;

        // Set the parsed data to the state
        setAppConfig(GiaUIAppConfig);
        setGlobalAppConfig(GiaGlobalAppConfig);
        setIsAppConfigLoading(false);
      })
      .catch((error) => {
        console.log("getAppConfig Error", error);
        setIsAppConfigLoading(false);
        setAppErrorMessage("Some thing went wrong. Please try again.");
      });
  }, [oktaToken]);

  // Azure web pubsub connection string
  const getClientAccessUrl = async () => {
    try {
      const { email, sub: userId } = authState?.idToken?.claims || {};
      setIsPubSubLoading(true);
      const res = await axios.post(
        globalAppConfig?.dynamicPromptsUrl,
        {
          pubsub: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaToken}`,
            "X-User-Email": email,
            "X-User-Aud": userId,
            "ocp-apim-subscription-key": globalAppConfig?.apiKey,
          },
        }
      );
      console.log("res", res);
      setPubsubClientAccessUrl(res.data);
      setIsPubSubLoading(false);
    } catch (error) {
      setIsPubSubLoading(false);
      console.log("getClientAccessUrl Error", error);
      setAppErrorMessage("Some thing went wrong. Please try again.");
    }
  };

  //Theme
  const [themeMode, setThemeMode] = useState(() => {
    // Retrieve theme mode from local storage on component mount
    const savedThemeMode = localStorage.getItem(THEME_MODE_KEY);
    return savedThemeMode || "light"; // Default to 'light' if not found in local storage
  });
  // const [theme, setTheme] = useState(createTheme(customPallete('light')))

  const toggleTheme = () => {
    const newThemeMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newThemeMode);
    // Save theme mode in local storage
    localStorage.setItem(THEME_MODE_KEY, newThemeMode);
  };

  // useEffect(() => {
  //     setTheme(createTheme(customPallete(themeMode)))
  // },[themeMode])

  const setSelectedBrandAndStore = (brand) => {
    setSelectedBrand(brand);
    localStorage.setItem(SELECTED_BRAND_KEY, JSON.stringify(brand));
  };

  const theme = useMemo(
    () => createTheme(customPallete(themeMode)),
    [themeMode]
  );

  return (
    <AppContext.Provider
      value={{
        theme,
        themeMode,
        toggleTheme,
        isConfigLoaded,
        globalAppConfig,
        appConfig,
        pubsubClientAccessUrl,
        selectedBrand,
        setSelectedBrand: setSelectedBrandAndStore,
        isAppConfigLoading,
        setIsAppConfigLoading,
        isPubSubLoading,
        setIsPubSubLoading,
        appErrorMessage,
        setAppErrorMessage,
        // setAppErrorMessageState,
        getClientAccessUrl,
        setIsConfigLoaded,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
};
const useAppContext = () => {
  return useContext(AppContext);
};

export { useAppContext, AppContext, AppContextProvider };

export default ({ fill = "#5A5A5A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="40"
      viewBox="0 0 41 40"
      fill={fill}
    >
      <mask x="0" y="0" width="41" height="40">
        <rect x="0.5" width="40" height="40" fill={fill} />
      </mask>
      <g>
        <path d="M26.4166 27.9721L28.4446 25.9446L21.9721 19.4446V11.3055H19.1946V20.5555L26.4166 27.9721ZM20.5 36.6667C18.213 36.6667 16.0557 36.2292 14.0279 35.3542C12.0001 34.4792 10.2315 33.2871 8.72206 31.778C7.2129 30.2685 6.02081 28.4999 5.14581 26.4721C4.27081 24.4443 3.83331 22.287 3.83331 20C3.83331 17.7131 4.27081 15.5557 5.14581 13.528C6.02081 11.5002 7.2129 9.73157 8.72206 8.22213C10.2315 6.71296 12.0001 5.52087 14.0279 4.64587C16.0557 3.77087 18.213 3.33337 20.5 3.33337C22.7869 3.33337 24.9443 3.77087 26.9721 4.64587C28.9998 5.52087 30.7685 6.71296 32.2779 8.22213C33.7871 9.73157 34.9791 11.5002 35.8541 13.528C36.7291 15.5557 37.1666 17.7131 37.1666 20C37.1666 22.287 36.7291 24.4443 35.8541 26.4721C34.9791 28.4999 33.7871 30.2685 32.2779 31.778C30.7685 33.2871 28.9998 34.4792 26.9721 35.3542C24.9443 36.2292 22.7869 36.6667 20.5 36.6667ZM20.5 33.8888C24.3241 33.8888 27.595 32.53 30.3125 29.8125C33.03 27.095 34.3887 23.8242 34.3887 20C34.3887 16.1759 33.03 12.905 30.3125 10.1875C27.595 7.47004 24.3241 6.11129 20.5 6.11129C16.6758 6.11129 13.405 7.47004 10.6875 10.1875C7.96998 12.905 6.61123 16.1759 6.61123 20C6.61123 23.8242 7.96998 27.095 10.6875 29.8125C13.405 32.53 16.6758 33.8888 20.5 33.8888Z" />
      </g>
    </svg>
  );
};

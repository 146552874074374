import React from "react";
import { styled, Paper } from "@mui/material";
import { Link } from "react-router-dom";

import AddNoteIcon from "../../icons/AddNoteIcon";
import HomeIcon from "../../icons/HomeIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import ClockIcon from "../../icons/ClockIcon";
import PoweredByIcon from "../../icons/PoweredByIcon";
import IconComponent from "./Icon";
import MenuTile from "./Menutile";
import ThemeSwitch from "../ThemeSwitch";

import { useAppContext } from "../../context/AppContext";

const SideMenuLayout = styled(Paper)(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { background_gray, gray } = theme?.palette[mode];
  return {
    width: "120px",
    backgroundColor: background_gray,
    color: gray,
    border: "none",
    borderRadius: 0,
    boxShadow: "unset",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "fixed",
    left: 0,
    height: "calc(100vh - 120px)",
  };
});

const MenuItem = (props) => {
  const { theme } = useAppContext();
  const { mode } = theme.palette;
  const { iconGray } = theme.palette[mode];
  const { Icon, label, style = {} } = props;
  return (
    <MenuTile style={{ ...style }}>
      {Icon && <IconComponent fill={iconGray} {...props}></IconComponent>}
      {label && (
        <MenuTile
          sx={{
            margin: 0,
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {label}
        </MenuTile>
      )}
    </MenuTile>
  );
};

const MenuItems = (props) => {
  const { theme } = useAppContext();
  const { mode } = theme.palette;
  const { iconGrayNew } = theme.palette[mode];
  const { Icon, label, style = {} } = props;
  return (
    <MenuTile style={{ ...style }}>
      {Icon && <IconComponent fill={iconGrayNew} {...props}></IconComponent>}
      {label && (
        <MenuTile
          sx={{
            margin: 0,
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {label}
        </MenuTile>
      )}
    </MenuTile>
  );
};

export default ({ children }) => {
  return (
    <SideMenuLayout className="side-menu-container">
      <div className="top-menu-items">
        <MenuItems Icon={PoweredByIcon} style={{ margin: "24px 14px 14px" }} />
        <Link to="/">
          <MenuItem Icon={HomeIcon} label="Home" />
        </Link>
        <Link to="/form">
          <MenuItem Icon={AddNoteIcon} label="New" />
        </Link>
        <Link to="/history">
          <MenuItem Icon={ClockIcon} label="History" />
        </Link>
      </div>

      <div className="bottom-menu-items">
        <MenuItem
          Icon={SettingsIcon}
          label="Admin"
          style={{ bottom: "10%", left: "20px" }}
        />
        <ThemeSwitch />
      </div>
      {children && children}
    </SideMenuLayout>
  );
};

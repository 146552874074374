import constants from "./constants";

const CLIENT_ID = constants.CLIENT_ID;
const ISSUER = constants.ISSUER;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;

const oktaConfig = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 120,
    storage: "sessionStorage",
  },
};

export default oktaConfig;
